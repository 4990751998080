$black: #101820
$blue: #afd2f8
$grayLight: #f7f7f9

$headerHeight: 85px
$baseSize: 22px
$scale: 1.5
$logoSize: 50px

$sectionPadding: 120px 20px
$easing: cubic-bezier(.666, 0, .333, 1)

