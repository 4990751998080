@use "settings" as *
@use "mixins" as *

@font-face
    font-family: 'il'
    src: url('../fonts/InriaSerif-Light.woff2') format('woff2')
    font-weight: 100
    font-display: swap

@font-face
    font-family: 'ib'
    src: url('../fonts/InriaSerif-Bold.woff2') format('woff2')
    font-weight: 100
    font-display: swap

@font-face
    font-family: 'k'
    src: url('../fonts/Kage.woff2') format('woff2')
    font-weight: 100
    font-display: swap

*
    box-sizing: border-box

body 
    font-family: "il"
    background: $grayLight
    margin: 0 
    color: #222 
    font-size: $baseSize
    line-height: $baseSize * $scale

    #main
        margin: $headerHeight 0 120px 0

        img
            width: 100%

        p, div
            a:not(.assetLibNavItem):not(.iconNavItem)
                &::after
                    transition: width 0.6s $easing
                    content: ""
                    position: absolute
                    right: 0
                    width: 60%
                    height: 2px
                    background: $black
                    bottom: -0.25rem

                &:hover::after
                    width: 100%

    &.portfolio
        #main
            margin: 0 0 120px 0

        header
            z-index: 0
            background: transparent

    a
        outline: 0
        text-decoration: none
        color: $black
        position: relative

    p
        margin-top: 0

        &:last-child
            margin-bottom: 0

h2
    margin: 0 0 3rem 0
    font-size: 74.25px
    font-family: "k"
    line-height: 74.25px

h1
    margin: 0 0 3rem 0
    font-size: 111.375px
    font-family: "k"
    line-height: 111.375px

.stickyBorder
    position: sticky
    top: $headerHeight
    border-bottom: solid 1px $black

.section
    &#projects, &#asset-library
        border-top: solid 1px $black

    &#asset-library
        border-bottom: solid 1px $black

    &[type="hero_text"]
        position: relative
        height: calc(100vh - #{$headerHeight})
        max-width: 1440px
        margin: 0 auto
        padding: 0 20px

        .sliceWrapper
            position: absolute
            bottom: 120px
            width: calc(100% - 60px)
            left: 20px

            p
                /** font-size: 74.25px **/
                line-height: clamp(4.375rem, 4.0662rem + 1.5439vw, 6.961rem)
                font-size: clamp(2.063rem, 1.7552rem + 1.5392vw, 4.6411rem)
                
                margin: 0

                strong
                    font-family: "k"
                    /** font-size: 120px **/
                    font-size: clamp(4.375rem, 4.0019rem + 1.8657vw, 7.5rem)
                    line-height: 0

    &[type="image-text-combo"], &[type="super_powers_list"]
        display: grid
        grid-template-columns: 50% 50%

        .sliceLeft
            border-right: solid 1px $black

        .image
            height: 100%
            background-size: cover
            background-position: center

            &[background]
                background-size: auto
                background-repeat: no-repeat

        .text
            padding: $sectionPadding

            p
                text-align: justify

    &[type="super_powers_list"]
        svg
            width: 2.25rem
            margin-right: 1.5rem

            path
                fill: $black

        .superPowerItem
            &:first-of-type
                margin-top: 4rem

            &:not(:last-child)
                margin-bottom: 3rem
                
            .superPowerLabel
                display: flex
                align-items: flex-start
                cursor: pointer
                font-size: 49.5px
                line-height: 49.5px

            .superPowerContent
                padding: 1rem 0 0 42px
                /** display: none **/
                margin-left: 18px
                border-left: dashed 2px $black

                p
                    text-align: left
                    margin-bottom: 0.75rem

                    &:last-child
                        margin-bottom: 0

    &[type="projects_list"]
        padding: $sectionPadding
        text-align: center

        .projectItem
            margin: 6rem 0

            .projectItemLabel
                /** font-size: 167.9625px **/
                /** line-height: 160px **/
                font-size: clamp(4.641rem, 3.9485rem + 3.4627vw, 10.441rem)
                line-height: clamp(4.641rem, 3.9485rem + 3.4627vw, 10.441rem)
                font-family: "k"
                color: $grayLight
                text-shadow: -0.5px -0.5px 0 $black, 0 -0.5px 0 $black, 0.5px -0.5px 0 $black, 0.5px 0 0 $black, 0.5px 0.5px 0 $black, 0 0.5px 0 $black, -0.5px 0.5px 0 $black, -0.5px 0 0 $black
                transition: color 0.6s, text-shadow 0.6s $easing
                margin-bottom: 2rem

            &:hover
                .projectItemLabel
                    color: $black
                    text-shadow: -0.25px -0.25px 0 $black, 0 -0.25px 0 $black, 0.25px -0.25px 0 $black, 0.25px 0 0 $black, 0.25px 0.25px 0 $black, 0 0.25px 0 $black, -0.25px 0.25px 0 $black, -0.25px 0 0 $black

header
    position: fixed
    width: 100%
    top: 0
    left: 0
    background: $grayLight
    z-index: 100
    height: $headerHeight

    #logo, #navigation
        height: 100%
        display: flex
        align-items: center

    #logo
        position: absolute
        left: 20px

        img
            height: $logoSize

    #navigation
        position: absolute
        right: 20px
        top: 0
        font-size: 17px
        line-height: 17px

        a
            &:not(:last-child)
                margin-right: 3.5rem

#pageNotFoundWrapper
    height: calc(100vh - #{$headerHeight} - 120px)
    padding: 0 20px
    text-align: center
    display: flex
    align-items: center
    justify-content: center

    div
        font-size: 33px
        line-height: 49.5px
        margin: 120px 0 25px 0

#assetLibNav
    white-space: nowrap
    padding: 0 20px

    .assetLibNavItem
        display: inline-block
        /** font-size: 167.0625px **/
        font-size: clamp(3.094rem, 2.2167rem + 4.3863vw, 10.441rem)
        line-height: clamp(3.094rem, 2.2167rem + 4.3863vw, 10.441rem)
        font-family: "k"
        color: $grayLight
        text-shadow: -0.5px -0.5px 0 $black, 0 -0.5px 0 $black, 0.5px -0.5px 0 $black, 0.5px 0 0 $black, 0.5px 0.5px 0 $black, 0 0.5px 0 $black, -0.5px 0.5px 0 $black, -0.5px 0 0 $black
        transition: color 0.6s, text-shadow 0.6s $easing

        &:not(:last-child)
            margin-right: 4rem

        &.active, &:hover
            color: $black
            text-shadow: -0.25px -0.25px 0 $black, 0 -0.25px 0 $black, 0.25px -0.25px 0 $black, 0.25px 0 0 $black, 0.25px 0.25px 0 $black, 0 0.25px 0 $black, -0.25px 0.25px 0 $black, -0.25px 0 0 $black

#iconNavWrapper
    position: fixed
    right: 20px
    text-align: right
    font-size: 17px
    line-height: 17px
    top: calc(#{$headerHeight} + 160px + 120px)

    a
        display: block

        &:not(:last-child)
            margin-bottom: 1.5rem

        span
            &:last-child
                margin-left: 1rem
                width: 2rem
                display: inline-block
                text-align: center

#pfWrapper
    height: 300vh

    #pfStickyBorder
            position: sticky
            top: 0
            height: $headerHeight
            border-bottom: solid 1px $black

    #pfHeroWrapper
        display: grid
        grid-template-columns: 65% 35%
        height: calc(100vh - #{$headerHeight})
        width: calc(100% - 117px)
        margin-left: auto

        #pfHeroImage
            background-size: cover
            background-position: center

        #pfHeroTextWrapper
            display: flex
            align-items: flex-end
            padding: 0 20px

            h1
                word-break: break-word

            p
                line-height: 1

